<template>
  <div id="cookie">
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> COOKIE PRIVACY </v-icon>
          </v-card-title>
          <v-card-text>Last updated on 01/12/2021</v-card-text>
          <v-card-text>
            SIA "LeadProm Media" (Matīsa iela, 61 - 31, Rīga, Latvia, LV-1009) (Merchanto "Us", "We", or "Our")
            understand that your privacy is important to you and are committed to being transparent about the
            technologies it uses. This Cookie Policy ("Cookie Policy") explains how and why cookies ( "Cookies") may be
            stored on and accessed from your device when you use or visit our website. Please read this Cookie Policy
            carefully before using our Site.
            <br /><br />
            This Privacy Policy should be read together with our Cookie Policy and our Terms of Use.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 1. WHAT ARE COOKIES? </v-icon>
          </v-card-title>
          <v-card-text>
            1.1. Cookies are small text files containing small amounts of data that are downloaded and may be stored on
            your device e.g. your computer (or other internet-enabled devices, such as a smartphone or tablet).
            <br /><br />
            1.2. This Cookie Policy provides you with data about the cookies we use and why. Our Privacy Policy sets out
            full details of the other data we may collect and how we may use your personal data.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 2. WHAT ARE COOKIES USED FOR? </v-icon>
          </v-card-title>
          <v-card-text>
            2.1. Merchanto use cookies to:
            <ul>
              <li>2.1.1. Improve the services provided to you;</li>
              <li>2.1.2. Customize our website;</li>
              <li>2.1.3. Facilitate the navigation of all visitors on our website;</li>
              <li>2.1.4. Provide a greater and better experience with us;</li>
              <li>2.1.5. Identify website problems for constant improvement;</li>
              <li>2.1.6. Make measurements and statistics of use;</li>
              <li>2.1.7. Show advertising related to preferences based on an analysis of the use of our website.</li>
            </ul>
            <br /><br />
            2.2. SIA "LeadProm Media" (Matīsa iela, 61 - 31, Rīga, Latvia, LV-1009) is responsible for the processing of
            your personal data.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 3. COOKIES USED ON THE WEBSITE </v-icon>
          </v-card-title>
          <v-card-text>
            3.1. To the extent that information processed in connection with cookies should qualify as personal data,
            the legal ground for that processing is your consent (Art. 6 (1) lit. (a) GDPR). So, by clicking “Yes” on
            the cookie banner, you give your consent to Merchanto to place and/or read several types of cookies from the
            website you are visiting.
            <br /><br />
            3.2. Merchanto uses several different types of cookies. Some of these are session cookies which are
            temporary and allow us to link your actions during a browser session. Session cookies are erased when you
            close the browser. Other cookies are persistent cookies that remain on your device for the period specified
            in the cookie. Depending on their function and their intended purpose, cookies can be assigned to the
            following categories:
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 4. STRICTLY NECESSARY COOKIES </v-icon>
          </v-card-title>
          <v-card-text>
            4.1. Strictly necessary cookies are required to navigate our website and operate basic website functions.
            Examples of strictly necessary cookies are cookies to remember your cookie settings or accessing secure
            areas of the website. Without these cookies, certain basic functionalities cannot be offered.
            <br /><br />
            4.2. Strictly necessary cookies are always active and will be placed without your consent.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 5. PERFORMANCE COOKIES </v-icon>
          </v-card-title>
          <v-card-text>
            5.1. Performance cookies collect information on your usage of our website. They identify e.g. your internet
            browser, operating system, visited websites, duration, and several website visits, previously visited
            websites, most commonly visited websites, and errors you experienced. The information collected is
            aggregated and anonymous. It does not allow personal identification. It only serves the purpose of
            evaluating and enhancing the experience of our website.
            <br /><br />
            5.2. To the extent that information processed in connection with performance cookies should qualify as
            personal data, the legal ground for that processing is your consent (Art. 6 (1) lit. (a) GDPR).
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 6. MARKETING COOKIES </v-icon>
          </v-card-title>
          <v-card-text>
            6.1. Marketing cookies will remember your product and page preferences and in general that you visited the
            website. We strive to provide you with advertisements relevant to you and your interests on other online
            platforms, where available based on your visits and surfing behavior on our website and other third-party
            websites. Based on these interests, we develop a segmented profile and then adapt the content and the
            advertisements on our website for various groups of customers.
            <br /><br />
            6.2. To the extent that information processed in connection with marketing cookies should qualify as
            personal data, the legal ground for that processing is your consent (Art. 6 (1) lit. (a) GDPR).
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 7. PREFERENCE COOKIES </v-icon>
          </v-card-title>
          <v-card-text>
            7.1. Preference cookies allow a website to remember choices you make (such as your language, or the region
            you are in) and provide more enhanced personal features. These cookies cannot track your browsing activity
            on other websites. They don’t gather any information about you that could be used for advertising or
            remembering where you’ve been on the Internet outside our website.
            <br /><br />
            7.2. To the extent that information processed in connection with preference cookies should qualify as
            personal data, the legal ground for that processing is your consent (Art. 6 (1) lit. (a) GDPR).
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 8. FIRST-PARTY AND THIRD-PARTY COOKIES </v-icon>
          </v-card-title>
          <v-card-text>
            8.1. We use both first-party and third-party cookies on our website.
            <br /><br />
            8.2. First-party cookies are cookies issued from https://merchanto.org/ domain that are generally used to
            identify language and location preferences or render basic website functionality.
            <br /><br />
            8.3. Third-party cookies belong to and are managed by other parties, such SIA Leadprom Media business
            partners or service providers. Some cookies, web beacons, and other tracking and storage technologies that
            we use are from third-party companies (third-party cookies), such as Facebook, Google Analytics. To provide
            us with web analytics and intelligence about our website which may also be used to provide measurement
            services and target ads. These companies use programming code to collect information about your interaction
            with our website, such as the pages you visit, the links you click on, and how long you are on our website.
            <br /><br />
            8.4. When we work with third-party vendors to apply cookies on our behalf and they have access to personal
            data collected through cookies or other applications, they are subject to appropriate contractual safeguards
            under the requirements of the GDPR and ePrivacy Directive (EPD) has become known as the “cookie law”. We
            retain personal data collected through cookies necessary for personalized services when you have consented
            to the use of cookies for those purposes, and only for the time necessary to aggregate personal data that we
            use for website performance and measurement of the audience.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 9. CONSENT </v-icon>
          </v-card-title>
          <v-card-text>
            9.1. Visitors are informed that they may withdraw their consent to abide by this policy at any time and may
            delete the cookies stored on their device through the settings and configurations of their browser that are
            mentioned below.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 10. HOW TO DISABLE COOKIES? </v-icon>
          </v-card-title>
          <v-card-text>
            10.1. Depending on the browser in which the visitor browses our website, it may allow, block or delete the
            cookies installed on their device by modifying the configuration parameters of the installed browser. To
            change your web browser settings for cookies, you can follow the instructions in the help section of your
            web browser or visit <a href="http://www.allaboutcookies.org/es/">http://www.allaboutcookies.org/es/</a>.
            You can also find information on how to disable specific cookies using the links provided below.
            <ul>
              <li>
                Google Chrome
                <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener">Google cookie settings</a>;
              </li>
              <li>
                Mozilla Firefox
                <a
                  href="https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies"
                  target="_blank" rel="noopener"
                  >Mozilla cookie settings</a
                >;
              </li>
              <li>
                Opera
                <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank" rel="noopener">Opera cookie settings</a>;
              </li>
              <li>
                Safari
                <a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac" target="_blank" rel="noopener"
                  >Safari cookie settings</a
                >;
              </li>
            </ul>
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 11. DATA CONSERVATION </v-icon>
          </v-card-title>
          <v-card-text>
            11.1. Some of the cookies used on the web save your data as long as the session is open.
            <br /><br />
            11.2. The data extracted by third-party cookies will be stored for 24 months.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 12. MODIFICATIONS TO THE POLICY </v-icon>
          </v-card-title>
          <v-card-text>
            12.1. This policy may be modified at any time by a legal provision or to meet the interests of Merchanto,
            for which, the latter will announce each update through this website. It is the responsibility of the
            visitor to review this policy constantly to know the changes made, if applicable.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 13. CONTACT US! </v-icon>
          </v-card-title>
          <v-card-text> 13.1. If you have any questions please contact us at support@merchanto.org. </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Privacy.vue',
};
</script>

<style lang="scss" scoped>
#privacy {
  ul > li {
    list-style: none;
  }
}
</style>
